





















































































































































































































































































































































import { SearchBuilder } from "@/builder";
import { debounce, debounceProcess } from "@/helpers/debounce";
import { Row, useDate } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { LogisticReceivingItemStateEnum } from "@/models/enums/receive-items.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouseBranch,
  DataWarehouseLocation,
  ReceivingItemListResponseDTO,
  StatusProduct,
} from "@/models/interface/logistic.interface";
import { ResponseProduct } from "@/models/interface/product.interface";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { productService } from "@/services/product.service";
import { ListContactDataDto } from "@interface/contact-data";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Moment } from "moment";
import printJs from "print-js";
import { Component, Vue } from "vue-property-decorator";

type FormField = {
  supplierName: string | null;
  poNumber: string | null;
  branch: string | null;
  warehouse: string | null;
  rack: string | null;
  receiveDateFrom: Moment | null;
  productCategory: string | null;
  receiveDateTo: Moment | null;
  grNumber: string | null;
  status: string | null;
};

@Component
export default class ListGoodReceiveChecklist extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  datalistSupplierName: Array<ListContactDataDto> = [];
  loadingdatalistsupplierName = false;
  datalistStatus: StatusProduct[] = [];
  datalistLocation: DataWarehouseLocation[] = [];
  loadingdatalistLocationRack = false;
  datalistLocationRack: Array<DataWarehouseLocation> = [];
  dataListItems: Array<Row<ReceivingItemListResponseDTO, string>> = [];
  dataBranch: DataWarehouseBranch[] = [];
  loadingdatalistLocation = false;
  loadingDownload = false;
  loadingCategory = false;
  loadingBranch = false;
  dataListCategory: ResponseProduct[] = [];
  totalData = 0;
  form!: WrappedFormUtils;
  optGrNumbers: Array<Option<ReceivingItemListResponseDTO>> = [];
  isFormSubmitted = false;
  loadingPrint = false;
  page = 0;
  limit = 10;
  loadingTable = false;
  loadingGRNumber = false;
  formRules = {
    supplierName: {
      label: "lbl_supplier",
      name: "supplierName",
      placeholder: "lbl_supplier",
      decorator: [
        "supplierName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    poNumber: {
      label: "lbl_po_number",
      name: "poNumber",
      placeholder: "lbl_po_number",
      decorator: [
        "poNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    warehouse: {
      label: "lbl_warehouse",
      name: "warehouse",
      placeholder: "lbl_warehouse",
      decorator: [
        "warehouse",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rack: {
      label: "lbl_rack",
      name: "rack",
      placeholder: "lbl_rack",
      decorator: [
        "rack",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    receiveDateFrom: {
      label: "lbl_receipt_date_from",
      name: "receiveDateFrom",
      placeholder: "lbl_receipt_date_from",
      decorator: [
        "receiveDateFrom",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    productCategory: {
      label: "lbl_part_category",
      name: "productCategory",
      placeholder: "lbl_part_category",
      decorator: [
        "productCategory",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    receiveDateTo: {
      label: "lbl_receipt_date_to",
      name: "receiveDateTo",
      placeholder: "lbl_receipt_date_to",
      decorator: [
        "receiveDateTo",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    goodReceiptNumber: {
      label: "lbl_good_receipt_number",
      name: "goodReceiptNumber",
      placeholder: "lbl_good_receipt_number",
      decorator: [
        "grNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status",
      decorator: [
        "status",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  columnsTable = [
    {
      title: this.$t("lbl_document_no"),
      dataIndex: "checklistNumber",
      key: "receivingChecklistNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_good_receipt_number"),
      dataIndex: "receiveNumber",
      key: "receiveNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_supplier"),
      dataIndex: "supplierName",
      key: "supplierName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_supplier_do_number"),
      dataIndex: "supplierDeliveryOrderNo",
      key: "supplierDeliveryOrderNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_receipt_date"),
      dataIndex: "receiveDate",
      key: "receivingDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_po_number"),
      dataIndex: "purchaseOrderNumber",
      key: "poNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "locationReceived",
      key: "locationReceived",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_action"),
      key: "operation",
      align: "center",
      scopedSlots: { customRender: "operation" },
    },
  ];

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
    };
  }

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "receivingitems" });
  }

  created() {
    this.onSearchGrNumber = debounceProcess(this.onSearchGrNumber);

    this.getListContact("");
    this.getListOfStatus();
    this.getListOfWarehouseLocation("");
    this.getListOfProductCategory("");
    this.getBranch("");
    this.getListOfWarehouseLocationRack("");
    this.getGRNumbers(new RequestQueryParams("", 0, "createdDate:desc"));
  }

  onSearchGrNumber(search = ""): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    if (search) {
      params.search = builder
        .push(["receivingItem.receiveNumber", search], { like: "both" })
        .build();
    }

    this.getGRNumbers(params);
  }

  getGRNumbers(params: RequestQueryParamsModel): void {
    this.loadingGRNumber = true;
    logisticServices
      .listOfReceivingItems(params)
      .then(res => {
        this.optGrNumbers = res.data.map<Option<ReceivingItemListResponseDTO>>(
          item => ({
            label: item.receiveNumber,
            key: item.id,
            value: item.id,
            meta: item,
          })
        );
      })
      .finally(() => {
        this.loadingGRNumber = false;
      });
  }

  getListOfProductCategory(value): void {
    let params = {
      limit: 10,
      page: 0,
      search: value,
    } as RequestQueryParamsModel;
    this.loadingCategory = true;
    productService
      .listProductCategory(params)
      .then((res: any) => {
        res.data.map((dataObject, index) => (dataObject.key = index));
        this.dataListCategory = res.data;
      })
      .finally(() => (this.loadingCategory = false));
  }
  clearItems() {
    this.form.resetFields();
  }

  buildSearch(field: FormField): string {
    const { toStartDay, toEndDay } = useDate();
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (field.branch) {
      q.push(
        builder
          .push([
            "locationReceived.warehouse.branchWarehouse.secureId",
            field.branch,
          ])
          .build()
      );
      builder.destroy();
    }

    if (field.grNumber) {
      q.push(builder.push(["secureId", field.grNumber]).build());
      builder.destroy();
    }

    if (field.poNumber) {
      q.push(
        builder
          .push(
            ["receivingItem.purchaseOrder.documentNumber", field.poNumber],
            {
              like: "both",
            }
          )
          .build()
      );
      builder.destroy();
    }

    if (field.productCategory) {
      q.push(
        builder
          .push(["product.category.secureId", field.productCategory])
          .build()
      );
      builder.destroy();
    }

    if (field.rack) {
      q.push(builder.push(["locationReceived.secureId", field.rack]).build());
      builder.destroy();
    }

    if (field.receiveDateFrom) {
      q.push(
        builder
          .push(
            [
              "receivingItem.receiveDate",
              toStartDay(field.receiveDateFrom).format(),
            ],
            { het: true }
          )
          .build()
      );
      builder.destroy();
    }

    if (field.receiveDateTo) {
      q.push(
        builder
          .push(
            [
              "receivingItem.receiveDate",
              toEndDay(field.receiveDateTo).format(),
            ],
            { let: true }
          )
          .build()
      );
      builder.destroy();
    }

    if (field.status) {
      q.push(builder.push(["receivingItem.status", field.status]).build());
      builder.destroy();
    }

    if (field.supplierName) {
      q.push(
        builder
          .push(["receivingItem.supplier.firstName", field.supplierName], {
            like: "both",
          })
          .build()
      );
      builder.destroy();
    }

    if (field.warehouse) {
      q.push(
        builder
          .push(["locationReceived.warehouse.secureId", field.warehouse])
          .build()
      );
      builder.destroy();
    }

    return q.join(builder.AND);
  }

  findItems(page: boolean): void {
    this.form.validateFields((err, values: FormField) => {
      const params = new RequestQueryParams(
        "",
        0,
        "createdDate:desc",
        this.limit
      );
      params.page = page ? this.page - 1 : 0;
      params.search = this.buildSearch(values);

      this.loadingTable = true;
      logisticServices
        .listOfReceivingItems(params)
        .then(res => {
          this.dataListItems = res.data.map<
            Row<ReceivingItemListResponseDTO, string>
          >(item => ({
            ...item,
            key: item.id,
          }));
          this.totalData = res.totalElements;
        })
        .finally(() => (this.loadingTable = false));
    });
  }

  buildParams(field: FormField): string {
    const { toDefaultFormat } = useDate();
    const company = this.$store.state.authStore.authData.companyName;
    const supplierName = field.supplierName || "ALL";
    const rack =
      this.datalistLocationRack
        .find(item => item.id === field.rack)
        ?.name.replaceAll(",", " ") || "ALL";
    const poNo = field.poNumber || "ALL";
    const status = field.status || "ALL";
    const grNo =
      this.optGrNumbers.find(item => item.value === field.grNumber)?.label ||
      "ALL";
    const dateFrom = field.receiveDateFrom
      ? toDefaultFormat(field.receiveDateFrom)
      : "ALL";
    const branch =
      this.dataBranch.find(item => item.id === field.branch)?.name || "ALL";
    const dateTo = field.receiveDateTo
      ? toDefaultFormat(field.receiveDateTo)
      : "ALL";
    const warehouse =
      this.datalistLocation.find(item => item.id === field.warehouse)?.name ||
      "ALL";

    const p: Array<string> = [
      company,
      supplierName,
      poNo,
      grNo,
      branch,
      warehouse,
      rack,
      status,
      dateFrom,
      dateTo,
    ];

    return p.join(",");
  }

  download(): void {
    this.form.validateFields((_err, values: FormField) => {
      const params = new RequestQueryParams();
      params.fromChecklist = true;
      params.params = this.buildParams(values);
      params.search = this.buildSearch(values);

      this.loadingDownload = true;
      logisticServices
        .downloadReportReceivingItems(params)
        .then(dataBlob => {
          if (dataBlob) {
            const url = window.URL.createObjectURL(new Blob([dataBlob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report_receiveitems.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loadingDownload = false));
    });
  }

  getListOfWarehouseLocationRack(value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (value && this.form.getFieldValue("warehouse")) {
      params.search = `name~*${value}*_AND_warehouse.secureId~${this.form.getFieldValue(
        "warehouse"
      )}`;
    } else if (!value && this.form.getFieldValue("warehouse")) {
      params.search = `warehouse.secureId~${this.form.getFieldValue(
        "warehouse"
      )}`;
    } else if (value && !this.form.getFieldValue("warehouse")) {
      params.search = `name~*${value}*`;
    }
    this.loadingdatalistLocationRack = true;
    logisticServices
      .listWarehouseLocation(params, "")
      .then(res => {
        res.data.forEach((dataObject, index) => (dataObject.key = index));
        this.datalistLocationRack = res.data;
      })
      .finally(() => (this.loadingdatalistLocationRack = false));
  }

  print(): void {
    this.form.validateFields((_err, values) => {
      const params = new RequestQueryParams();
      params.fromChecklist = true;
      params.search = this.buildSearch(values);
      params.params = this.buildParams(values);

      this.loadingPrint = true;
      logisticServices
        .printReportReceivingItems(params)
        .then(dataBlob => {
          if (dataBlob) {
            const url = window.URL.createObjectURL(new Blob([dataBlob]));
            printJs(url);
          }
        })
        .finally(() => (this.loadingPrint = false));
    });
  }

  handleBranch() {
    this.form.setFieldsValue({
      warehouse: null,
      rack: null,
    });
    debounce(() => {
      this.getListOfWarehouseLocation("");
    }, 500);
  }
  handleLocation() {
    this.form.setFieldsValue({
      rack: null,
    });
    debounce(() => {
      this.getListOfWarehouseLocationRack("");
    }, 500);
  }
  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loadingBranch = false));
  }
  getListOfWarehouseLocation(value) {
    let params = {
      limit: 10,
      page: 0,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    if (value && this.form.getFieldValue("branch")) {
      params.search = `name~*${value}*_AND_branchWarehouse.secureId~${this.form.getFieldValue(
        "branch"
      )}`;
    } else if (!value && this.form.getFieldValue("branch")) {
      params.search = `branchWarehouse.secureId~${this.form.getFieldValue(
        "branch"
      )}`;
    } else if (value && !this.form.getFieldValue("branch")) {
      params.search = `name~*${value}*`;
    }
    this.loadingdatalistLocation = true;
    logisticServices
      .listWarehouse(params, "")
      .then((res: any) => {
        res.data.map((dataObject, index) => (dataObject.key = index));
        this.datalistLocation = res.data;
      })
      .finally(() => (this.loadingdatalistLocation = false));
  }
  getListOfStatus() {
    let params = {
      name: "RECEIVE_ITEM_STATUS",
    } as RequestQueryParamsModel;
    masterServices.listMaster(params).then(res => {
      this.datalistStatus = res;
    });
  }
  getListContact(value) {
    let params = {
      limit: 10,
      page: 0,
      search: `supplier~true_AND_active~true`,
    } as RequestQueryParamsModel;
    if (value) {
      params.search = `supplier~true_AND_active~true_AND_firstName~*${value}*`;
    } else {
      params.search = `supplier~true_AND_active~true`;
    }
    this.loadingdatalistsupplierName = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.datalistSupplierName = res.data;
      })
      .finally(() => (this.loadingdatalistsupplierName = false));
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  onChangeTable(pagination: {
    total: number;
    current: number;
    pageSize: number;
  }): void {
    this.page = pagination.current;
    if (this.limit !== pagination.pageSize) {
      this.page = 1;
    }

    this.limit = pagination.pageSize;
    this.findItems(true);
  }

  allowEdit(status: LogisticReceivingItemStateEnum): boolean {
    const editStatus: Array<LogisticReceivingItemStateEnum> = [
      LogisticReceivingItemStateEnum.DRAFT,
      LogisticReceivingItemStateEnum.WAIT_FOR_APPROVAL,
    ];
    return editStatus.includes(status);
  }
}
